<template>
    <q-tree class="square"
        ref="domTree"
        v-model:ticked="selectedModules"
        :nodes="SystemTree"
        node-key="ModuleID"
        children-key="Children"
        no-connectors
        selected-color="primary"
        default-expand-all
        tick-strategy="leaf"
        @update:ticked="onSelectedModules"
    >
        <template v-slot:default-header="prop">
            <div class="row items-center">
                <q-icon
                    :name="prop.node.RouteIcon || 'folder_open'"
                    color="primary"
                    size="28px"
                    class="q-mr-sm"
                />
                <div class="text-weight-bold text-primary">
                    {{ prop.node.ModelName }}
                </div>
            </div>
        </template>
    </q-tree>
</template>


<script>
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState("SysRole", ["RoleEntity"]),
        ...mapState("SysRoleModules", ["SystemTree", "RoleModules"]),
    },
    watch: {
        RoleEntity: {
            handler(val) {
                if (val.RoleID)
                    this.$nextTick(() => {
                        this.onLoadRoleModules();
                        this.actLoadSystemTree();
                    });
            },
            immediate: true,
            deep: true,
        },
        RoleModules: {
            handler(val) {
                if (val) {
                    this.selectedModules = [...val];
                }
            },
            immediate: true,
            deep: true,
        },
    },
    data() {
        return {
            selectedModules: [],
        };
    },
    methods: {
        onLoadSysUserList() {
            this.$store.dispatch("SysUser/actLoadData", {
                HospID: this.$store.getters["appUser/hospID"],
            });
        },

        ...mapActions("SysRoleModules", [
            "actLoadRoleModules",
            "actLoadSystemTree",
            "actSetRoleModules"
        ]),
        onLoadRoleModules() {
            this.actLoadRoleModules({ RoleID: this.RoleEntity.RoleID }).then(
                () => {
                    setTimeout(() => {
                        this.$refs.domTree.expandAll();
                    }, 1000);
                }
            );
        },
        onSelectedModules(e){
            this.actSetRoleModules(e)
        }
    },
};
</script>